import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import React from 'react';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
// import TextField from '@material-ui/core/TextField';
import Alert from '@material-ui/lab/Alert';
import PasswordField from '@sprinx/react-mui-fields/PasswordField';
import TextField from '@sprinx/react-mui-fields/TextField';
import LockIcon from '@material-ui/icons/LockTwoTone';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslate } from '@sprinx/react-globalize';
import DialogConfirm from '@sprinx/react-mui-components/DialogConfirm';
import IconButton from '@material-ui/core/IconButton';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Helmet } from 'react-helmet';
import { isAuthenticatedState } from '../../api/appState';
import { AfterPageProps, wrapLayout } from '../../@sprinx/react-after-razzle';
import { useApiClient } from '../../api/appState';
import useLoginHandler from '../../api/auth/useLoginHandler';
import { customerNotificationOpenState } from '../../api/customerNotification';
import { enqueueSnackbarMessage } from '../../api/snackbars';
import useRouteUrlFactory from '../../hooks/useRouteUrlFactory';
import LoginRegistrationDialog from '../LoginPage/components/LoginRegistrationDialog';
import InfoLayout from '../../layouts/InfoLayout/InfoLayout';
import { localeState } from '../../api/appState';
import SimpleImage from './components/SimpleImage';

export type HomePageNewProps = AfterPageProps;
export type HomePageNewClassNameKey =
  | 'root'
  | 'content'
  // | 'headline'
  | 'fields'
  | 'actions'
  // | 'logo'
  | 'loginButton'
  | 'forgotFieldsContainer'
  | 'registrationButton'
  | 'carousel'
  | 'sideLoginPanel'
  | 'hpContent'
  | 'infoBox'
  | 'infoBoxHeading'
  | 'infoBoxContent'
  | 'infoBoxContentSection'
  | 'carouselWrapper'
  | 'imgSection'
  // | 'backToImg'
  | 'mainWrapper';

const useStyles = makeStyles((theme: Theme) =>
  createStyles<HomePageNewClassNameKey, {}>({
    root: {
      paddingTop: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(1),
      },
      '@media (max-width: 1100px)': {
        paddingTop: '80px',
      },
    },
    imgSection: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      '& img': {
        width: '100%',
        height: 'auto',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    content: {},
    sideLoginPanel: {
      minWidth: '300px',
      maxWidth: '350px',
      padding: theme.spacing(2, 3),
      paddingLeft: '0',
      borderRight: '1px solid #e0e0e0',
      marginRight: '10px',
      '@media (max-width: 1100px)': {
        display: 'flex',
        minWidth: 'unset',
        maxWidth: 'unset',
        flexDirection: 'column',
        borderRight: 'none',
        borderBottom: '1px solid #e0e0e0',
        marginRight: '0',
        paddingRight: '0',
      },
    },
    // backToImg: {
    //   '& img': {
    //     width: '100%',
    //     height: 'auto',
    //     objectFit: 'contain',
    //     maxWidth: '100%',
    //   },
    // },
    hpContent: {
      paddingTop: '40px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '40px',
      maxWidth: '900px',
      width: '100%',
    },
    mainWrapper: {
      display: 'flex',
      flexDirection: 'row',
      gap: '20px',
      padding: '0 20px',
      background: 'white',
      border: '1px solid #e0e0e0',
      '@media (max-width: 1100px)': {
        display: 'flex',
        flexDirection: 'column',
      },
    },
    infoBox: {
      border: '1px solid #e0e0e0',
      borderRadius: '5px',
      marginBottom: '20px',
      padding: '10px',
    },
    infoBoxHeading: {
      textAlign: 'center',
      textTransform: 'uppercase',
      color: '#002969',
      background: '#C6C6C6',
      borderTop: `2px solid #002969`,
      borderBottom: `2px solid #002969`,
      padding: '5px',
      marginTop: 0,
    },
    infoBoxContent: {
      paddingLeft: '10px',
    },
    carouselWrapper: {
      aspectRatio: '16/10',
      minHeight: '200px',
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      '& img': {
        width: '100% !important',
        height: 'auto !important',
        objectFit: 'contain',
        maxWidth: '100%',
      },
    },
    fields: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '20px',
      '& > h3': {
        color: '#002969',
        textTransform: 'uppercase',
        marginBottom: 0,
      },
      '& > div': {
        margin: 0,
      },
    },
    actions: {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '10px',
      '& > a': {
        cursor: 'pointer',
        marginRight: 'auto',
      },
    },
    forgotFieldsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    },
    loginButton: {
      backgroundColor: '#002969',
      color: 'white',
      '&:hover': {
        backgroundColor: '#003485',
      },
    },
    registrationButton: {
      backgroundColor: theme.palette.appBar.background,
      '&:hover': {
        backgroundColor: lighten(theme.palette.appBar.background, 0.2),
      },
    },
    carousel: {
      '& a': {
        display: 'inline-block',
      },
    },
    infoBoxContentSection: {
      display: 'flex',
      flexDirection: 'column',
      margin: theme.spacing(2, 0),
    },
  }),
);

const initialValues = {
  username: '',
  password: '',
  forgotUser: '',
};

interface Values {
  forgotUser: string;
  password: string;
  username: string;
}

const HomePageNew: React.FC<HomePageNewProps> = ({ history }) => {
  const classes = useStyles();
  const [values, setValues] = React.useState<Values>(initialValues);
  const [error, setError] = React.useState<boolean>(false);
  const isAuthenticated = useRecoilValue(isAuthenticatedState);
  const [open, setOpen] = React.useState<boolean>(false);
  const [openRegistration, setOpenRegistration] = React.useState<boolean>(false);
  const showMessage = useSetRecoilState(enqueueSnackbarMessage);
  const apiClient = useApiClient();
  const setNotificationOpen = useSetRecoilState(customerNotificationOpenState);
  const routeUrlFactory = useRouteUrlFactory();
  const handleChange = React.useCallback(
    (fieldName: keyof Values) => (value: string | undefined) => {
      setValues((ps) => ({ ...ps, [fieldName]: value }));
    },
    [],
  );

  const locale = useRecoilValue(localeState);

  const handlePasswordReset = React.useCallback(() => {
    if (values.forgotUser) {
      apiClient
        .post<{ email: string }, { username: string }>('/v1/users/renew-password', {
          username: values.forgotUser,
        })
        .then(
          ({ email }) => {
            showMessage({ message: t('loginPage/messages/passwordSent', { email: email || '' }), variant: 'success' });
            setValues(initialValues);
            setOpen(false);
          },
          () => {
            showMessage({ message: t('loginPage/messages/errorUpdatingPassword'), variant: 'error' });
          },
        );

      // ApiClient
      // .then((res) => {
      //    showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
      //    setValues(initialValues);
      //    setOpen(false);
      // })
      // .catch(() => showMessage({ message: 'Neplatné uživatelské jméno', variant: 'error' }))
      // showMessage({ message: 'Heslo bylo odesláno na Váš e-mail', variant: 'success' });
    }
  }, [values, showMessage, apiClient]);

  const handleLogin = useLoginHandler(
    () => {
      setNotificationOpen(true);
      // const afterLoginPath = location?.search
      //   ? (qs.parse(location?.search, { ignoreQueryPrefix: true })?.from as string)
      //   : routeUrlFactory('customerProfile');
      // TODO: add from?
      const afterLoginPath = routeUrlFactory('catalogue');

      history.replace(afterLoginPath);
    },
    (err) => {
      if (process.env.NODE_ENV !== 'production') {
        console.error('Login Error ', err);
      }
      setError(true);
    },
  );
  const [logoutConfirm, setLogoutConfirm] = React.useState<boolean>(false);
  const t = useTranslate();

  const handleOpenRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(true);
  }, []);

  const handleCloseRegistrationDialog = React.useCallback(() => {
    setOpenRegistration(false);
  }, []);

  // const handleDrawerToggle = React.useCallback((v) => {
  //   setOpen(typeof v === 'boolean' ? v : (prev) => !prev);
  // }, []);

  return (
    <>
      {/* <AppHeader handleDrawerToggle={handleDrawerToggle} /> */}
      <Helmet>
        <script async src='https://www.googletagmanager.com/gtag/js?id=G-75F7P7Y36E'></script>
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-75F7P7Y36E');
        `}
        </script>
      </Helmet>
      <Container className={classes.root}>
        <div className={classes.mainWrapper}>
          <div className={classes.sideLoginPanel}>
            {/* <div>
              <div className={classes.backToImg}>
                {locale === 'cs' ? (
                  <Link href='https://eshop.mapomedical.cz'>
                    <SimpleImage src='/Banner-Vstup_na_stare_B2B.jpg' alt='vstup na starý web' />
                  </Link>
                ) : (
                  <Link href='https://eshop.mapomedical.cz'>
                    <SimpleImage src='/Banner-Vstup_na_stare_B2B_EN.jpg' alt='entrance to old web' />
                  </Link>
                )}
              </div>
            </div> */}
            {locale === 'cs' ? (
              <div className={classes.infoBox}>
                <h3 className={classes.infoBoxHeading}>Info Box</h3>
                <div className={classes.infoBoxContent}>
                  <div className={classes.infoBoxContentSection}>Vážení obchodní partneři,</div>
                  <div className={classes.infoBoxContentSection}>
                    <span>vítejte na našem novém B2B.</span>
                    <span>V případě technických problémů</span>
                    <span>s registrací a používáním tohoto webu</span>
                    <span>kontaktujte technickou podporu</span>
                  </div>
                  <div className={classes.infoBoxContentSection}>
                    <Link href='mailto:provoz@mapo.cz'>provoz@mapo.cz</Link>
                    <span>+420 606 711 216</span>
                  </div>

                  <div className={classes.infoBoxContentSection}>
                    <div>E-mailové adresy pro přímé vyřízení:</div>
                    <div>
                      OBJEDNÁVEK = <Link href='mailto:sklad@mapo.cz'>sklad@mapo.cz</Link>
                    </div>
                    <div>
                      REKLAMACÍ = <Link href='mailto:reklamace@mapo.cz'>reklamace@mapo.cz</Link>
                    </div>
                    <div>
                      ZASLÁNÍ FAKTUR = <Link href='mailto:fakturace@mapo.cz'>fakturace@mapo.cz</Link>
                    </div>
                    <div>
                      REGISTRACÍ a OPRAV DAT = <Link href='mailto:provoz@mapo.cz'>provoz@mapo.cz</Link>
                    </div>
                  </div>
                  <p>
                    Obchodní podmínky zůstávají po dobu paralelního provozu
                    <br />
                    starého a nového B2B beze změny.
                  </p>
                  <p>Vaše MAPO a.s.</p>
                </div>
              </div>
            ) : (
              <div className={classes.infoBox}>
                <h3 className={classes.infoBoxHeading}>Info Box</h3>
                <div className={classes.infoBoxContent}>
                  <div className={classes.infoBoxContentSection}>Dear business partners,</div>
                  <div className={classes.infoBoxContentSection}>
                    <span>welcome to our new B2B.</span>
                    <span>In case of any technical problems</span>
                    <span>with registration and using this website,</span>
                    <span>please contact technical support</span>
                  </div>
                  <div className={classes.infoBoxContentSection}>
                    <Link href='mailto:provoz@mapo.cz'>provoz@mapo.cz</Link>
                    <span>+420 606 711 216</span>
                  </div>

                  <div className={classes.infoBoxContentSection}>
                    <div>Email addresses for direct processing:</div>
                    <div>
                      ORDERS = <Link href='mailto:sklad@mapo.cz'>sklad@mapo.cz</Link>
                    </div>
                    <div>
                      COMPLAINTS = <Link href='mailto:reklamace@mapo.cz'>reklamace@mapo.cz</Link>
                    </div>
                    <div>
                      SENDING INVOICES = <Link href='mailto:fakturace@mapo.cz'>fakturace@mapo.cz</Link>
                    </div>
                    <div>
                      REGISTRATIONS and DATA CORRECTIONS = <Link href='mailto:provoz@mapo.cz'>provoz@mapo.cz</Link>
                    </div>
                  </div>
                  <p>
                    The terms and conditions remain unchanged during the parallel operation
                    <br />
                    of the old and new B2B.
                  </p>
                  <p>Your MAPO a.s.</p>
                </div>
              </div>
            )}
            {!isAuthenticated && (
              <div className={classes.infoBox}>
                <h3 className={classes.infoBoxHeading}>{(t('loginPage/accessToCatalogue') || '').toUpperCase()}</h3>
                <p className={classes.infoBoxContent}>{t('loginPage/accessToCatalogueContent')}</p>
              </div>
            )}
            {!isAuthenticated ? (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  handleLogin(values.username, values.password);
                }}
              >
                <div className={classes.content}>
                  <div className={classes.fields}>
                    <h3>{t('loginPage/twoWordsLogin')}</h3>
                    {error && (
                      <Alert severity='error'>
                        <strong>{t('loginPage/loginError')}</strong> {t('loginPage/wrongCredentials')}
                      </Alert>
                    )}
                    <TextField
                      type='text'
                      label={t('common/username')}
                      onChange={handleChange('username')}
                      value={values.username}
                      fullWidth
                      margin='normal'
                    />
                    <PasswordField
                      label={t('common/password')}
                      onChange={handleChange('password')}
                      value={values.password}
                      fullWidth
                      margin='normal'
                    />
                  </div>
                  <div className={classes.actions}>
                    <Button type='submit' className={classes.loginButton} variant='contained'>
                      {t('loginPage/login')}
                    </Button>
                    <Button
                      onClick={handleOpenRegistrationDialog}
                      component={'a'}
                      className={classes.registrationButton}
                      variant='contained'
                    >
                      {t('loginPage/register')}
                    </Button>
                    <Link component={'a'} onClick={() => setOpen((ps) => !ps)}>
                      {t('loginPage/forgotPassword')}
                    </Link>
                  </div>
                  <Collapse in={open}>
                    <div className={classes.forgotFieldsContainer}>
                      <TextField
                        type='text'
                        label={t('loginPage/enterYourEmail')}
                        onChange={handleChange('forgotUser')}
                        value={values.forgotUser}
                        fullWidth
                        margin='normal'
                      />
                      <Button variant='contained' color='primary' size='small' onClick={handlePasswordReset}>
                        {t('common/send')}
                      </Button>
                    </div>
                  </Collapse>
                </div>
              </form>
            ) : (
              <div>
                <Tooltip title={t('app/logout')}>
                  <IconButton
                    edge='end'
                    color='inherit'
                    onClick={() => {
                      setLogoutConfirm(true);
                    }}
                  >
                    <LockIcon />
                  </IconButton>
                </Tooltip>
                <DialogConfirm
                  open={logoutConfirm}
                  onClose={() => {
                    setLogoutConfirm(false);
                  }}
                  onConfirm={() => {
                    history.push(routeUrlFactory('logout'));
                  }}
                  title={t('app/logout')}
                >
                  <div>{t('app/logoutConfirmation')}</div>
                </DialogConfirm>
              </div>
            )}
          </div>

          <div className={classes.hpContent}>
            <div className={classes.carouselWrapper}>
              {locale === 'cs' ? (
                <Carousel
                  className={classes.carousel}
                  autoPlay
                  infiniteLoop
                  interval={5000}
                  showStatus={false}
                  showThumbs={false}
                >
                  <Link
                    href={routeUrlFactory('product', {
                      params: { sn: 'med-comfort-high-risk-rukavice', id: '6603e1e479b33d001b587ec8' },
                    })}
                  >
                    <SimpleImage src='/homapageBanners/banner_karusel_01.jpg' alt='Banner - karusel 1' />
                  </Link>
                  <Link href={routeUrlFactory('categories', { params: { categories: 'a3' } })}>
                    <SimpleImage src='/homapageBanners/banner_karusel_02.jpg' alt='Banner - karusel 2' />
                  </Link>
                  <Link href={routeUrlFactory('categories', { params: { categories: 'a4' } })}>
                    <SimpleImage src='/homapageBanners/banner_karusel_03.jpg' alt='Banner - karusel 3' />
                  </Link>
                </Carousel>
              ) : (
                <Carousel
                  className={classes.carousel}
                  autoPlay
                  infiniteLoop
                  interval={5000}
                  showStatus={false}
                  showThumbs={false}
                >
                  <Link
                    href={routeUrlFactory('product', {
                      params: { sn: 'med-comfort-high-risk-rukavice', id: '6603e1e479b33d001b587ec8' },
                    })}
                  >
                    <SimpleImage
                      src='/homapageBanners/homapageBanners/banner_karusel_01.jpg'
                      alt='Banner - karusel 2'
                    />
                  </Link>
                  <Link href={routeUrlFactory('categories', { params: { categories: 'a3' } })}>
                    <SimpleImage src='/homapageBanners/banner_karusel_02.jpg' alt='Banner - karusel 2' />
                  </Link>
                  <Link href={routeUrlFactory('categories', { params: { categories: 'a4' } })}>
                    <SimpleImage src='/homapageBanners/banner_karusel_03.jpg' alt='Banner - karusel 3' />
                  </Link>
                </Carousel>
              )}
            </div>
            {locale === 'cs' ? (
              <div className={classes.imgSection}>
                <Link href={routeUrlFactory('categories', { params: { categories: 'a1' } })}>
                  <SimpleImage src='/homapageBanners/banner_akce.jpg' alt='Info banner - Akce' />
                </Link>
                <Link href={routeUrlFactory('categories', { params: { categories: 'a2' } })}>
                  <SimpleImage
                    src='/homapageBanners/banner_vyprodej.jpg'
                    alt='Info banner - výprodej skladových zásob'
                  />
                </Link>
              </div>
            ) : (
              <div className={classes.imgSection}>
                <Link href={routeUrlFactory('categories', { params: { categories: 'a1' } })}>
                  <SimpleImage src='/homapageBanners/banner_akce.jpg' alt='Info banner - Action' />
                </Link>
                <Link href={routeUrlFactory('categories', { params: { categories: 'a2' } })}>
                  <SimpleImage src='/homapageBanners/banner_vyprodej.jpg' alt='Info banner - warehouse stock sale' />
                </Link>
              </div>
            )}
          </div>
        </div>
      </Container>
      <LoginRegistrationDialog openDialog={openRegistration} onClose={handleCloseRegistrationDialog} />
    </>
  );
};

HomePageNew.displayName = 'HomePageNew';

export default wrapLayout(HomePageNew, InfoLayout);
